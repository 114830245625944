.styled-range-picker-dropdown .ant-picker-panel-container {
  padding: 12px 12px 0;
}

.styled-range-picker-dropdown .ant-picker-ranges {
  padding: 12px 0;
  line-height: 1;
}

.styled-range-picker-dropdown .ant-picker-footer {
  position: relative;
}

.ant-picker-cell::before {
  display: none;
}
/* long selector is mandatory here to overwrite ant picker styles */
.ant-picker-date-panel
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end
  .ant-picker-cell-inner::after {
  left: 0;
}
